<mat-sidenav-container>
  <mat-sidenav #sidenav class="sidenav">
    <mat-nav-list role="navigation">
      <a mat-list-item (click)="scroll('header')">
        <mat-icon matListIcon>home</mat-icon>
        Home
      </a>
      <a mat-list-item (click)="scroll('projects')">
        <mat-icon matListIcon>code</mat-icon>
        Projects
      </a>
      <a mat-list-item (click)="scroll('about')">
        <mat-icon matListIcon>person</mat-icon>
        About
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content class="sidenav-content">
    <mat-toolbar class="toolbar mat-elevation-z4" color="primary">
      <button mat-icon-button fxHide.gt-xs (click)="sidenav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
      <span id="home">Lukas Gregori</span>
      <span class="example-fill-remaining-space"></span>
      <span fxHide.xs>
        <button mat-button (click)="scroll('header')">Home</button>
        <button mat-button (click)="scroll('projects')">Projects</button>
        <button mat-button (click)="scroll('about')">About</button>
      </span>
    </mat-toolbar>
    <main class="content">
      <header id="header"></header>
      <div class="narrow-content">
        <router-outlet></router-outlet>
      </div>
      <website-footer></website-footer>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>
