export interface EducationEntry {
  location: string;
  description: string;
  duration: string;
}

export class EducationEntryData {
  public static educationEntries: EducationEntry[] = [
    {
      location: 'BG/BRG Mössingerstraße',
      description: '',
      duration: '2003-2007'
    },
    {
      location: 'HTBL Mössingerstraße',
      description: '',
      duration: '2007-2012'
    },
    {
      location: 'Graz University of Technology,',
      description: 'Bachelor Computer Science',
      duration: '2013-2016'
    },
    {
      location: 'Graz University of Technology,',
      description: 'Master Computer Science',
      duration: '2016-now'
    }
  ];
}
