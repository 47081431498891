import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FooterComponent} from './footer/footer.component';
import {
  MatButtonModule,
  MatCardModule,
  MatGridListModule,
  MatIconModule,
  MatListModule,
  MatProgressBarModule,
  MatSidenavModule,
  MatToolbarModule
} from '@angular/material';
import {FlexLayoutModule} from '@angular/flex-layout';
import {BlogPostsComponent} from './blog-posts/blog-posts.component';
import {AboutComponent} from './about/about.component';
import {HomePageComponent} from './home-page/home-page.component';
import {DataPrivacyComponent} from './data-privacy/data-privacy.component';
import {MatDialogModule} from '@angular/material/dialog';
import {SafeHtmlPipe} from './util/safe.html.pipe';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    BlogPostsComponent,
    AboutComponent,
    HomePageComponent,
    DataPrivacyComponent,
    SafeHtmlPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    FlexLayoutModule,
    MatCardModule,
    MatGridListModule,
    MatProgressBarModule,
    HttpClientModule,
    MatDialogModule
  ],
  entryComponents: [DataPrivacyComponent],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
