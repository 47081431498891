<div class="home-page-banner">

  <picture>
    <source media="(min-width: 1280px)" srcset="assets/images/background/WebsiteBackgroundBW.jpg">
    <source media="(min-width: 750px)" srcset="assets/images/background/WebsiteBackgroundTabletBW.jpg">
    <img src="assets/images/background/WebsiteBackgroundMobileBW.jpg" alt="Main Background Image"/>
  </picture>

  <div class="home-page-banner-content">
    <h2>Hi! I'm Lukas Gregori</h2>
    <p>Developer from Graz, Austria</p>
  </div>
</div>
<website-blog-posts></website-blog-posts>
<website-about></website-about>
