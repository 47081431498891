<footer>
  <a (click)="scroll('header')">
    <i class="fas fa-chevron-up"></i>
  </a>
  <br>
  <a href="https://github.com/LGrege">
    <i class="fab fa-github"></i>
  </a>
  <br>
  <a (click)="openDataPrivacyDialog()">
    Data privacy declaration
  </a>
  <br>
  <a href="mailto:contact@lukasgregori.com">
    Contact: contact@lukasgregori.com
  </a>
</footer>
