import {Component} from '@angular/core';
import {DataPrivacyComponent} from '../data-privacy/data-privacy.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'website-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  constructor(public dialog: MatDialog) {
  }

  scroll(id) {
    document.getElementById(id).scrollIntoView(
      {block: 'start', behavior: 'smooth'});
  }

  openDataPrivacyDialog() {
    this.dialog.open(DataPrivacyComponent);
  }
}
