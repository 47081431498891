import {Component} from '@angular/core';

@Component({
  selector: 'website-data-privacy',
  templateUrl: './data-privacy.component.html',
  styleUrls: ['./data-privacy.component.scss']
})
export class DataPrivacyComponent {

}
