export abstract class AbstractResizeableComponent {

  public numbCols: number;

  protected constructor(private breakpoints: GridListBreakpoint[]) {
  }

  public updateBreakpoint(width: number) {
    this.breakpoints.forEach(breakpoint => {
      if (breakpoint.from <= width && breakpoint.to >= width) {
        this.numbCols = breakpoint.cols;
        return;
      }
    });
  }

  onResize(event) {
    this.updateBreakpoint(event.target.innerWidth);
  }
}

export interface GridListBreakpoint {
  from: number;
  to: number;
  cols: number;
}
