export interface SkillEntry {
  description: string;
  amount: number;
}

export class SkillEntryData {
  public static skillEntries: SkillEntry[] = [
    {
      description: 'Java',
      amount: 100
    },
    {
      description: 'SAP Commerce / Hybris',
      amount: 90
    },
    {
      description: 'Spring',
      amount: 80
    },
    {
      description: 'REST / SOAP',
      amount: 90
    }
  ];
}
