import {Component, OnInit} from '@angular/core';
import {AbstractResizeableComponent} from '../abstract-resizeable/abstract-resizeable.component';
import {EducationEntry, EducationEntryData} from './data/education.entry';
import {SkillEntry, SkillEntryData} from './data/skill.entry';

@Component({
  selector: 'website-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent extends AbstractResizeableComponent implements OnInit {

  educationEntries: EducationEntry[];

  skillEntries: SkillEntry[];

  constructor() {
    super([{from: 0, to: 1279, cols: 1}, {from: 1280, to: 4000, cols: 3}]);
    this.educationEntries = EducationEntryData.educationEntries;
    this.skillEntries = SkillEntryData.skillEntries;
  }

  ngOnInit() {
    this.updateBreakpoint(window.innerWidth);
  }
}
