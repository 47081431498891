<mat-toolbar class="headline-about toolbar mat-elevation-z4" color="accent" id="about">
  <p>About</p>
</mat-toolbar>
<mat-grid-list [cols]="numbCols" rowHeight="fit" class="grid-list-about" (window:resize)="onResize($event)">
  <!------------------ About myself ------------------>
  <mat-grid-tile [colspan]="1" [rowspan]="1">
    <mat-card class="about-card">
      <mat-card-header class="about-myself-header">
        <div mat-card-avatar class="example-header-image"></div>
        <mat-card-title>About myself</mat-card-title>
      </mat-card-header>
      <p>
        <span>Hi!</span> I am Lukas, backend developer and student enrolled in computer science,
        living in Graz and currently working as Tech Lead at <a href="https://www.netconomy.net/en/">Netconomy</a>.
        <br><br>
        Checkout my latest projects above and feel free to contact me should you be
        interested in additional information on one of them.
      </p>
    </mat-card>
  </mat-grid-tile>
  <!------------------ Skills ------------------>
  <mat-grid-tile class="tile-blog-post" [colspan]="1" [rowspan]="1">
    <mat-card class="about-card">
      <mat-list>
        <h3 matSubheader>Skills
          <mat-icon>build</mat-icon>
        </h3>
        <div *ngFor="let skillEntry of skillEntries;">
          <mat-list-item class="mat-list-item-skills">
            <p matLine>{{skillEntry.description}}</p><br>
            <mat-progress-bar color="accent" matLine mode="determinate" value="{{skillEntry.amount}}">
            </mat-progress-bar>
          </mat-list-item>
        </div>
      </mat-list>
    </mat-card>
  </mat-grid-tile>
  <!------------------ Education ------------------>
  <mat-grid-tile class="tile-blog-post" [colspan]="1" [rowspan]="1">
    <mat-card class="about-card">
      <mat-list>
        <h3 matSubheader>Education
          <mat-icon>school</mat-icon>
        </h3>
        <div *ngFor="let educationEntry of educationEntries; let isLast = last;">
          <mat-list-item class="mat-list-item-education">
          <span class="about-education-left">
            <div class="about-education-location-icon">
            <mat-icon>location_on</mat-icon>
              </div>
            <div class="about-education-location-content">
              {{educationEntry.location}}<br>
              {{educationEntry.description}}
            </div>
          </span>
            <span class="about-education-right">
              <mat-icon>date_range</mat-icon>
              {{educationEntry.duration}}
            </span>
          </mat-list-item>
          <div *ngIf="!isLast">
            <mat-divider></mat-divider>
          </div>
        </div>
      </mat-list>
    </mat-card>
  </mat-grid-tile>
</mat-grid-list>
