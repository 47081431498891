import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BlogPostsService {

  private static readonly BLOG_POSTS_URL = './assets/data/blog-posts.json?nocache=22122020';

  constructor(private http: HttpClient) {
  }

  public getBlogPosts(): Observable<BlogPost[]> {
    return this.http.get<BlogPost[]>(BlogPostsService.BLOG_POSTS_URL);
  }
}

export interface BlogPost {
  headline: string;
  text: string;
  imageURL: string;
  githubURL: string;
}
