import {Component} from '@angular/core';

@Component({
  selector: 'website-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'website';

  scroll(id) {
    document.getElementById(id).scrollIntoView(
      {block: 'start', behavior: 'smooth'});
  }
}
