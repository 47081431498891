import {Component, OnInit} from '@angular/core';
import {BlogPost, BlogPostsService} from '../blog-posts.service';
import {AbstractResizeableComponent} from '../abstract-resizeable/abstract-resizeable.component';

@Component({
  selector: 'website-blog-posts',
  templateUrl: './blog-posts.component.html',
  styleUrls: ['./blog-posts.component.scss'],
})
export class BlogPostsComponent extends AbstractResizeableComponent implements OnInit {

  public blogPosts: BlogPost[];

  constructor(private blogPostService: BlogPostsService) {
    super([{from: 0, to: 960, cols: 1}, {from: 959, to: 4000, cols: 3}]);
  }

  ngOnInit() {
    this.blogPostService.getBlogPosts().subscribe((posts: BlogPost[]) => this.blogPosts = posts);
    this.updateBreakpoint(window.innerWidth);
  }
}
