<mat-toolbar class="headline-blog-post toolbar mat-elevation-z4" color="accent" id="projects">
  <p>Recent Projects</p>
</mat-toolbar>
<mat-grid-list [cols]="numbCols" rowHeight="fit" class="grid-list-blog-post" (window:resize)="onResize($event)">
  <div *ngFor="let blogPost of blogPosts">
    <mat-grid-tile class="tile-blog-post" [colspan]="1" [rowspan]="1">
      <mat-card class="card-blog-post">
        <mat-card-title>
          {{blogPost.headline}}
          <a href="{{blogPost.githubURL}}">
            <i class="fab fa-github"></i>
          </a>
        </mat-card-title>
        <img class="img-blog-post" mat-card-image src="{{blogPost.imageURL}}" alt="{{blogPost.headline}}">
        <mat-card-content><br>
          <p class="mat-card-text" [innerHTML]="blogPost.text | safeHtml"></p>
        </mat-card-content>
      </mat-card>
    </mat-grid-tile>
  </div>
</mat-grid-list>
